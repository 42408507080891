import React from "react"
import ranjana from '../images/ranjana_plain.svg'

const IntroPage = () => {
  return (
    <div>
      <h2 className="major">Intro</h2>
      <span className="image main">
        <img src={ranjana} alt="IntroRanjana" />
      </span>
      <p>
        Sumedh Shakya, is a Software Engineer with prolific experience of Python and Django Technologies. He has over 4 years of experience in the Software Industry with his speciality in Backend Stacks but inadapted to DevOps and Data Engineering too. He had led multiple engineering teams over the years to access and execute high-level complex projects for International Clients through numerous Outsourcing Companies based in Kathmandu, Nepal. 
      </p>
      <p>
        Some of his areas of strength includes: Python, Django, Django REST Framework, Golang, Docker, Serverless Application, Application Load Balancer, SQL, PostgreSQL, Socket.io, Linux, Git and Agile Methodologies and approach to software development.
      </p>
      <p>
        He has also taken the role of Python Developer Mentor to Trainees over his journey and has a strong penchant to learning and exploring new technologies. He holds a Bachelor's Degree in Computer Engineering and has been a recipient of many awards along for Mathematics for his excelsior performance and knowledge at his University. He churns in flawless codes and would be an asset to any Software Engineering Team. 
      </p>
      <p>
        He also is a handicraft artist working on silver and copper statues. He has been actively involved in handicraft business delivering all quality statues.
      </p>
    </div>
  )
}
export default IntroPage