import React from "react"
import company from '../images/experience/company.png'
import freelance from '../images/experience/freelance.png'
import habitat from '../images/experience/habitat.png'
import ing from '../images/experience/innovate.png'
import iw from '../images/experience/iw.svg'
import outcode from '../images/experience/outcode.png'
import wesionary from '../images/experience/wesionary.svg'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component'

const Experience = () => {
  return (
    <div>
      <h2 className="major">Experience</h2>
      <VerticalTimeline className="verticle-timeline-class" layout="1-column-left" animate={false}>

        {/* InnovateTech */}
        <VerticalTimelineElement
          className="verticle-timeline-class vertical-timeline-element--work"
          iconStyle={{ background: `rgb(255, 255, 255) url(${ing}) no-repeat center/cover` }}
        >
          <h3 className="vertical-timeline-element-title">DevOps Engineer</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ color: '#000'}}>Innovate Tech (Feb 2023 - Present)</h4>
          <div>
            <ul>
              <li>Container Orchestration using Kubernetes and deployment using helm charts.</li>
              <li>Provision Infrastructure and Configuration Management using Terraform.</li>
              <li>Implement Mobile DevOps for Multi Flavour Android Apps release using Fastlane and Gitlab CICD.</li>
              <li>Implement and optimize centralized CI/CD for backend, frontend and lambda services using Gitlab CICD.</li>
              <li>Implementation of EKS IRSA for AWS resources access management, KEDA and VPA for autoscaling.</li>
              <li>Reduced cloud cost by ~40% with implementation of VPC Endpoint for network code reduction and secure data transmission.</li>
              <li>Contribution to logging and monitoring solutions like Grafana, Prometheus, Loki, Tempo, Pyroscope.</li>
            </ul>
          </div>
        </VerticalTimelineElement>

        {/* Outcode */}
        <VerticalTimelineElement
          style={{ width: '100%' }}
          className="verticle-timeline-class vertical-timeline-element--work"
          iconStyle={{ background: `url(${outcode}) no-repeat center/cover`, color: '#fff' }}
        >
          <h3 className="vertical-timeline-element-title">DevOps / Team Lead Engineer</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ color: '#000'}}>Outcode LLC (Dec 2020 - Feb 2023)</h4>
          <div>
            <ul>
              <li>Create and maintain fully automated CI/CD pipelines using Github Actions.</li>
              <li>Actively manage, improve, and monitor cloud infrastructure on AWS, including backups, patches and scaling.</li>
              <li>Reduced CI/CD pipeline execution time by ~75% by creating a python base image with all the necessary dependencies installed and docker caching.</li>
              <li>Build and deploy Docker containers to break up monolithic apps into microservices, improving developer workflow, increasing scalability, and optimizing speed.</li>
              <li>Trained backend and frontend team of 15 engineers on Docker, Serverless Application Deployment.</li>
              <li>Developed the entire backend module using Python on Django with DRF and FastAPI.</li>
              <li>Involvement in Client Communication, Project Planning, Requirement Analysis and Project Estimation.</li>
              <li>Mentor group of Trainees and Junior Developers.</li>
            </ul>
          </div>
        </VerticalTimelineElement>

        {/* HabitatNepal */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: `rgb(255, 255, 255) url(${habitat}) no-repeat center/cover`, color: '#fff' }}
        >
          <h3 className="vertical-timeline-element-title">Freelance Backend Engineer</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ color: '#000'}}>Habitat for Humanity Nepal (Mar 2021 - Feb 2023)</h4>
          <div>
            <ul>
              <li>Provided Maintenance Support to their existing Project Monitoring Tool.</li>
              <li>Optimized Application Performance using Multithreading and breaking single monolithic API into multiple micro APIs.</li>
            </ul>
          </div>
        </VerticalTimelineElement>

        {/* WesionaryTEAM */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: `rgb(0, 0, 0) url(${wesionary}) no-repeat center/contain` }}
        >
          <h3 className="vertical-timeline-element-title">Software Engineer</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ color: '#000'}}>Wesionary Team (Jun 2020 - Dec 2020)</h4>
          <div>
            <ul>
              <li>Developed the entire backend module using Golang on Gin web framework and GORM ORM.</li>
              <li>Integrated Database migration tool in Gin framework along with shell scripts to generate migration files.
                {/* <a href="https://medium.com/wesionary-team/integrating-migration-tool-in-gin-framework-golang-139676bc4af7">
                  &nbsp;(<i><u>link</u></i>)
                </a> */}
              </li>
            </ul>
          </div>
        </VerticalTimelineElement>

        {/* Freelance */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: `rgb(255, 255, 255) url(${freelance}) no-repeat center/contain` }}
        >
          <h3 className="vertical-timeline-element-title">Freelance Software Engineer</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ color: '#000'}}>Sept 2020 - Jun 2020</h4>
          <div>
            <ul>
              <li>Developed Serverless application using AWS Lambda and S3.</li>
              <li>Firebase Hosting and Cloud Firestore Integration</li>
              <li>Developed the entire backend module using Python on Django with DRF.</li>
            </ul>
          </div>
        </VerticalTimelineElement>

        {/* InsightWorkshop */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: `rgb(255, 255, 255) url(${iw}) no-repeat center/cover` }}
        >
          <h3 className="vertical-timeline-element-title">Software Engineer</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ color: '#000'}}>Insight Workshop (May 2018 - Nov 2019)</h4>
          <div>
            <ul>
              <li>Created and maintained fully automated CI/CD pipelines using AWS CodeCommit, CodePipeline, CodeBuild and CodeDeploy.</li>
              <li>Developed multi tenant web applications using Django and Angular.</li>
              <li>Built socket server using socket.io for real time notification.</li>
              <li>Data visualization for IoT device using d3.js.</li>
            </ul>
          </div>
        </VerticalTimelineElement>

        {/* SorusNepal */}
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          iconStyle={{ background: `rgb(255, 255, 255) url(${company}) no-repeat center/contain` }}
        >
          <h3 className="vertical-timeline-element-title">Django Developer</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ color: '#000'}}>Sorus Nepal Pvt. Ltd. (Apr 2017 - May 2018)</h4>
          <div>
            <ul>
              <li>Data migration scripts using Python and SQLAlchemy ORM</li>
              <li>Built web applications using Django with DRF</li>
            </ul>
          </div>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  )
}
export default Experience
