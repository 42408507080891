import React from "react"
import kec from '../images/education/kec.png'
import moliss from '../images/education/moliss.jpg'
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component'

const Education = () => {
  return (
    <div>
      <h2 className="major">Education</h2>
      <VerticalTimeline className="verticle-timeline-class" layout="1-column-left" animate={false}>

        {/* Bachelors */}
        <VerticalTimelineElement
          className="verticle-timeline-class vertical-timeline-element--work"
          iconStyle={{ background: `rgb(255, 255, 255) url(${kec}) no-repeat center/cover` }}
        >
          <h3 className="vertical-timeline-element-title">Bachelor in Computer Engineering</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ color: '#000'}}>Kantipur Engineering College (Nov 2013 - Sept 2017)</h4>
        </VerticalTimelineElement>

        {/* +2 */}
        <VerticalTimelineElement
          className="verticle-timeline-class vertical-timeline-element--work"
          iconStyle={{ background: `rgb(255, 255, 255) url(${moliss}) no-repeat center/cover` }}
        >
          <h3 className="vertical-timeline-element-title">+2 Science</h3>
          <h4 className="vertical-timeline-element-subtitle" style={{ color: '#000'}}>MOLISS College (Jul 2011 - Apr 2013)</h4>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  )
}
export default Education
